import styled from "styled-components"

export const HeroBar = styled.div`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  & .container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
    &:hover,
    :focus {
      text-decoration: none;
      color: #333333;
    }
    color: #333333;
    & span {
      font-weight: 600;
    }
  }
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
`

export const BreadCrumb = styled.ol`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
  & .item {
    display: -ms-flexbox;
    display: flex;
    & + .item {
      padding-left: 0.5rem;
    }
    & + .item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/";
    }
    & + .item:hover::before {
      text-decoration: underline;
    }
    & + item:hover::before {
      text-decoration: none;
    }
    &.active {
      color: #6c757d;
    }
  }
`
export const Progress = styled.div`
  display: none;
  position: fixed;
  top: 1px;
  z-index: 9999;
  background-color: #e5e9eb;
  height: 0.25em;
  width: 100%;
  & .bar {
    animation-duration: 6s;
    animation-name: width;
    background-image: linear-gradient(to right, #333333, #333333);
    background-size: 100% 0.25em;
    height: 100%;
    position: relative;
  }
  @keyframes width {
    0%,
    100% {
      transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
    }
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`
