import React from "react"
import Hero from "./Hero/Hero"
import { isSSR } from "./Script/script"

import "../style/main.scss"

const Contact = React.lazy(() => import("./Contact/Contact"))
const ProgressLoad = React.lazy(() => import("./ProgressRouteLoad/Progree"))
const Footer = React.lazy(() => import("./Footer/Footer"))

function Layout({ children, NoADS }) {
  return (
    <>
      <Hero />
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ProgressLoad />
        </React.Suspense>
      )}
      <div style={{ minHeight: "50vh" }}>{children}</div>

      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Contact NoADS={NoADS} />
          <Footer />
        </React.Suspense>
      )}
    </>
  )
}

export default Layout
