// capitalize title
export const nameCapitalized = theName => {
  return theName.charAt(0).toUpperCase() + theName.slice(1)
}

export const isSSR = typeof window === "undefined"

export function scrollTo(query) {
  const el = document.querySelector(query)

  if (el) {
    try {
      // Scroll smoothly to the element
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      })
    } catch (e) {
      // Fallback for browsers that don't support scrollIntoViewOptions
      el.scrollIntoView(true)
    }
  }
}

export const createTextAreaToCopy = e => {
  let elem = document.createElement("textarea")
  document.body.appendChild(elem)
  elem.value = e
  elem.select()
  document.execCommand("copy")
  document.body.removeChild(elem)
}
