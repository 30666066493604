import React from "react"
import { Link } from "gatsby"
import { HeroBar } from "../../StyleComponents/Layout"
import { isSSR } from '../Script/script';

const ShareApi = React.lazy(() => import("../ShareApi/ShareApi"))

const Header = () => {
  return (
    <>
      <HeroBar className="mb-3" id="hero">
        <div className="container">
          <Link to="/" className="brand">
            <span className="h3">
              <span className="logoDesign">Unit</span>Pedia
            </span>
          </Link>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <ShareApi />
            </React.Suspense>
          )}
        </div>
      </HeroBar>
    </>
  )
}

export default Header
